import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { Grid3x3 } from "@mui/icons-material";
import CountUp from "react-countup";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
function StateCard() {
  const [category, setCategory] = useState("");
  const [menuItems, setMenuitems] = useState("");
  const [orderList, setOrderList] = useState("");
  const GetCategory = () => {
    const token = sessionStorage.getItem("Token");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "http://192.168.29.172:8899/api/v1/admin/CategoriesListCount",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setCategory(response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  const GetMenuitems = () => {
    const token = sessionStorage.getItem("Token");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "http://192.168.29.172:8899/api/v1/admin/MenuitemListCount",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setMenuitems(response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  const OrderListbyCount = () => {
    const token = sessionStorage.getItem("Token");

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "http://192.168.29.172:8899/api/v1/admin/OrderListCount",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setOrderList(response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  // useEffect(() => {
  //   GetCategory();
  //   GetMenuitems();
  //   OrderListbyCount();
  // }, []);
  const content = [
    { name: "Category", data:10  },
    { name: "Sub Category", data: 100 },
    { name: "Total Products", data: 14000 },
    { name: "Customers", data: 100 },
  ];
  return (
    <Grid container spacing={1} justifyContent="center" sx={{ p: 1, }}>
      {content.map((item, index) => (
        <Grid key={index} item xs={6} md={3} lg={3}>
          <Box sx={{ p: 1 }}>
            <Card
              variant="outlined"
              sx={{
                height: 150,
                backgroundColor: "#11192a",
                color: "#fefefe",
                border: "1px solid black",
                boxShadow: "2px 2px 5px black",
                "&:hover": {
                  transform: "scale(1.1)",
                  transition: "all 0.5s",
                  color: "primary",
                  boxShadow: "5px 5px 5px gray",
                  // textShadow:'5px 5px 5px gray',
                  border: "1px solid black",
                },
              }}
            >
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                  align="center"
                >
                  <Grid3x3 sx={{ color: "white" }} />
                </Typography>

                <Typography variant="h4" align="center">
                  {item.name}
                </Typography>
                <Typography
                  sx={{ mt: 2 }}
                  variant="h5"
                  component="div"
                  align="center"
                >
                  <CountUp end={item.data} duration={2.5} />+
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}

export default StateCard;
