import React, { useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Add,
  ArrowForward,
  DeleteTwoTone,
  EditTwoTone,
  ForkRight,
} from "@mui/icons-material";
import axios from "axios";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

function AddsubCategory() {
  const { catid } = useParams();
  const [subCategory, setsubCategory] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [catname, setCatname] = useState("");
  const handleEditItem = (index, id) => {
    setEditMode(true);
    setEditIndex(id);
    setName(subCategory[index].name);
    handleDialogOpen(true);
  };
  const getsubcategory = () => {
    const token = sessionStorage.getItem("Token");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://simplesoft.co.in/cgshop/api/v1/admin/getSubCategory/${catid}`,
      headers: {
        Authorization: `Bearer ${token} `,
      },
    };
    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setsubCategory(response.data.subcat);
        setCatname(response.data.cat);
      })
      .catch((error) => {
        // console.log(error);
        toast.error("something went wrong", {
          position: "bottom-right",
          duration: 3000,
        });
      });
  };
  useEffect(() => {
    getsubcategory();
  }, []);
  // transform: "scale(0.9)",
  // transition: " all ease-in 0.2s",
  // "&:hover": {
  //   // color: "red",
  //   transform: "scale(1)",
  //   filter: `drop-shadow(8px 8px 10px black)`,
  // },
  const [Dialogopen, setDialogOpen] = useState(false);
  const [name, setName] = useState("");

  const handleDialogOpen = (id, name) => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
    setName("");
    setEditMode(false);
  };
  const addsubCategory = () => {
    if (editMode) {
      const token = sessionStorage.getItem("Token");
      let data = {
        name: name,
        cat_id: catid,
      };
      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `https://simplesoft.co.in/cgshop/api/v1/admin/updateSubCategory/${editIndex}`,
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));
          getsubcategory();
          handleDialogClose();
          toast.success(response.data, {
            position: "bottom-right",
            duration: 3000,
          });
        })
        .catch((error) => {
          // console.log(error);
          toast.error("something went wrong", {
            position: "bottom-right",
            duration: 3000,
          });
        });
    } else {
      let data = {
        name: name,
        cat_id: catid,
      };
      const token = sessionStorage.getItem("Token");
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://simplesoft.co.in/cgshop/api/v1/admin/addSubCategory",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token} `,
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));
          getsubcategory();
          handleDialogClose();
          toast.success(response.data, {
            position: "bottom-right",
            duration: 3000,
          });
        })
        .catch((error) => {
          // console.log(error);
          toast.error("something went wrong", {
            position: "bottom-right",
            duration: 3000,
          });
        });
    }
  };
  const [deleteDialog, setdeleteDialog] = React.useState(false);
  const [deleteid, setDeleteid] = useState("");
  const [deletename, setDeletename] = useState("");
  const deleteDialogOpen = (id, name) => {
    setdeleteDialog(true);
    setDeleteid(id);
    setDeletename(name);
  };

  const deleteDialogClose = () => {
    setdeleteDialog(false);
    setDeleteid("");
    setDeletename("");
  };
  const deletesubcategory = (id) => {
    const token = sessionStorage.getItem("Token");

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `https://simplesoft.co.in/cgshop/api/v1/admin/deleteSubCategory/${deleteid}`,
      headers: {
        Authorization: `Bearer ${token} `,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        getsubcategory();
        deleteDialogClose();
        toast.success(response.data, {
          position: "bottom-right",
          duration: 3000,
        });
      })
      .catch((error) => {
        // console.log(error);
        toast.error("something went wrong", {
          position: "bottom-right",
          duration: 3000,
        });
      });
  };
  const navigate = useNavigate();
  const goback = () => {
    navigate(-1);
  };
  const theme = useTheme();
  const smallscreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <Dialog
        open={deleteDialog}
        onClose={deleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete??</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            are you sure delete {deletename} &nbsp;Chapter ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteDialogClose}>Cancel</Button>
          <Button color="error" onClick={deletesubcategory} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ height: "30px", marginTop: "5px", marginLeft: "10px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Box onClick={goback} sx={{ cursor: "pointer" }}>
            <Typography fontSize={16} color="text.primary">
              Home
            </Typography>
          </Box>

          <Typography color="text.primary" variant="h5">
            {catname}
          </Typography>
        </Breadcrumbs>
      </div>

      <Grid container spacing={2} sx={{ pr: 2, pt: 1, mb: 7 }}>
        {subCategory.map((item, index) => (
          <Grid key={index} item xs={6} md={4} lg={3} sx={{ p: 1 }}>
            <Card
              sx={{
                // maxWidth: 345,
                width: smallscreen ? 190 : 260,
                height: 120,
                borderRadius: 2,
                backgroundSize: "cover",
                transform: "scale(0.9)",
                transition: " all ease-in 0.2s",
                "&:hover": {
                  transform: "scale(1)",
                  filter: `drop-shadow(8px 8px 10px black)`,
                },
              }}
              key={index}
            >
              <CardContent >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ whiteSpace: "pre-wrap" }}
                  >
                    {item.name?.toUpperCase()}
                  </Typography>
                </div>
              </CardContent>
              {/* <CardActions> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 10,
                }}
              >
                <div>
                  <IconButton
                    onClick={() => handleEditItem(index, item.id)}
                    color="primary"
                    size="small"
                    sx={{
                      "&:hover": {
                        transform: "scale(1.1)",
                        transition: "all 0.5s",
                      },
                    }}
                  >
                    <Tooltip title="Edit">
                      <EditTwoTone />
                    </Tooltip>
                  </IconButton>
                  <IconButton
                    onClick={() => deleteDialogOpen(item.id, item.name)}
                    color="error"
                    size="small"
                    sx={{
                      "&:hover": {
                        transform: "scale(1.1)",
                        transition: "all 0.5s",
                      },
                    }}
                  >
                    <Tooltip title="Delete">
                      <DeleteTwoTone />
                    </Tooltip>
                  </IconButton>
                </div>
                <div>
                  {" "}
                  <IconButton
                    component={Link}
                    to={`/category/${catid}/subcat/${item.id}`}
                    // onClick={() => handleEditItem(index, item.id)}
                    color="primary"
                    size="small"
                    sx={{
                      "&:hover": {
                        transform: "scale(1.1)",
                        transition: "all 0.5s",
                      },
                    }}
                  >
                    <Tooltip title={`go to ${item.name}`}>
                      <ArrowForward />
                    </Tooltip>
                  </IconButton>
                </div>
              </div>
              {/* </CardActions> */}
            </Card>
            {/* <Typography gutterBottom variant="h5" component="div">
              {item.name}
            </Typography> */}
          </Grid>
        ))}
      </Grid>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Button
          // color="warning"
          className="target-1"
          variant="contained"
          sx={{ width: "80%", p: 1, position: "fixed", bottom: 10 }}
          onClick={handleDialogOpen}
        >
          <Add /> Add New Sub Category
        </Button>
      </div>
      <Dialog
        open={Dialogopen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h3">Add Sub Category Name</Typography>
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            placeholder="subcategory Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
           
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          {/* <Button color="error"> */}
          <Button onClick={addsubCategory}>
            {editMode ? "Update" : "Add"}
          </Button>
          <Button onClick={handleDialogClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddsubCategory;
