import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Invoice from "./Pdffile/Invoice";
import PdfCard from "./Pdffile/PdfCard";

function Orderdetails() {
  const { id } = useParams();
  const [data, setdata] = useState([]);
  const [wholedata, setwholedata] = useState({});
  const [add, setadd] = useState({});
  // const [loading, setloading] = useState(true);
  const token = sessionStorage.getItem("Token");

  const getdata = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://simplesoft.co.in/cgshop/api/v1/admin/orders/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token} `,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        setdata(response.data[0].item_data);
        setwholedata(response.data[0]);
        setadd(JSON.parse(response.data[0].delivery_address));
        // console.log(JSON.parse(response.data[0].delivery_address))
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getdata();
  }, []);
  // console.log(data);
  return (
    <div style={{ padding: "10px" }}>
      <Grid container spacing={2}>
        <Grid item lg={6} xs={6}>
          <Typography variant="h5" sx={{ mb: 0.5 }}>
            Order No:- {wholedata.order_number}
          </Typography>
          <Typography variant="h5" sx={{ mb: 0.5 }}>
            Customer Name:- {wholedata.customer_name}
          </Typography>
          <Typography variant="h5" sx={{ mb: 0.5 }}>
            Total Quantity:- {wholedata.quantity}
          </Typography>
          <Typography variant="h5" sx={{ mb: 0.5 }}>
            Total Price:-{wholedata.total_price}
          </Typography>
        </Grid>
        <Grid item lg={6} xs={6}>
          <Typography variant="h4">Delivery Address</Typography>
          <>
            <Typography variant="h5">
              Customer Name:-{" "}
              <span style={{ fontSize: 17, fontWeight: 700 }}>{add.name}</span>
            </Typography>
            <Typography variant="h5">
              Customer Address:-{" "}
              <span style={{ fontSize: 17, fontWeight: 700 }}>
                {add.address}
              </span>
            </Typography>
            <Typography variant="h5">
              Customer City:-{" "}
              <span style={{ fontSize: 17, fontWeight: 700 }}>{add.city}</span>
            </Typography>
            <Typography variant="h5">
              Customer City:-{" "}
              <span style={{ fontSize: 17, fontWeight: 700 }}>{add.state}</span>
            </Typography>
            <Typography variant="h5">
              Customer Postal Code:-{" "}
              <span style={{ fontSize: 17, fontWeight: 700 }}>
                {add.postalCode}
              </span>
            </Typography>
            <Typography variant="h5">
              Customer Number:-{" "}
              <span style={{ fontSize: 17, fontWeight: 700 }}>
                {add.number}
              </span>
            </Typography>
          </>
        </Grid>
      </Grid>
      <Box>
        <List>
          {data.map((item, i) => (
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  sx={{ height: 60, width: 60, mr: 5 }}
                  src={`https://simplesoft.co.in/cgshop/uploads/${item.data.cat_id}/${item.data.subcat_id}/${item.data.id}/${item.data.img}`}
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography sx={{ fontSize: 15, fontWeight: 600 }}>
                    {item.data.title}
                  </Typography>
                }
                secondary={
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: 14, fontWeight: 650 }}>
                      Price:- {item.data.price}&nbsp;Quantity:- {item.quantity}
                    </Typography>
                    <Typography sx={{ fontSize: 14, fontWeight: 650 }}>
                      Total Price:- {item.data.price * item.quantity}Rs
                    </Typography>
                  </div>
                }
              />
            </ListItem>
          ))}
        </List>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: 5,
          }}
        >
          <Typography variant="h5" sx={{ mb: 0.5 }}>
            Total Price:-{wholedata.total_price}
          </Typography>
        </div>
      </Box>
    </div>
  );
}

export default Orderdetails;
