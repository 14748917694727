import { React, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import AuthUser from "../admin/AuthUser";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import toast, { Toaster } from "react-hot-toast";
import { styled } from "@mui/system";
import axios from "axios";
import { useAuth } from "./AuthContext/AuthContext";

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(3),
}));

const StyledForm = styled("form")({
  width: "100%", // Fix IE 11 issue.
  marginTop: 1,
});

const StyledSubmitButton = styled(Button)({
  margin: "16px 0 24px",
});

function Login() {
  //   const { http, setToken, token } = AuthUser();
  const navigate = useNavigate();
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      setEmailError("Please enter your email.");
    } else if (!emailRegex.test(email)) {
      setEmailError("Invalid email format.");
    }
    setEmailError("");
    return true;
  };

  const validatePassword = () => {
    if (!password) {
      setPasswordError("Please enter your password.");
    } else if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters long.");
    }
    setPasswordError("");
    return true;
  };
  const { login } = useAuth();
  const handleSubmit = (e) => {
    e.preventDefault();

    const isEmailValid = validateEmail();
    const isPasswordValid = validatePassword();

    if (isEmailValid && isPasswordValid) {
      // Add your login logic here
      //   console.log("Logging in with:", email, password);
    }
    const data = { email, password };

    axios
      .post("https://simplesoft.co.in/cgshop/api/v1/auth/login", data)
      .then((res) => {
        // console.log(res.data.access_token);
        // setToken(res.data.user, res.data.access_token);
        console.log(res.data)
        sessionStorage.setItem('userid',res.data.user.id)
        sessionStorage.setItem("Token", res.data.access_token);
        if (res.data.user.is_admin) {
          
          navigate("/admin/dashboard");
          login("admin");
          sessionStorage.setItem("user_admin", 1);

        }
        
      })
      .catch((error) => {
        console.log(error);
        toast.error(`Invalid Username or Password`, {
          position: "bottom-left",
          duration: 3000,
        });
      });
     
  };
  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <StyledPaper>
        {" "}
        <Typography variant="h4">Welcome to DigiMenu Dashboard</Typography>
      </StyledPaper>

      <Container component="main" maxWidth="xs">
        <StyledPaper elevation={6}>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <StyledForm onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setemail(e.target.value)}
              error={!!emailError}
              helperText={emailError}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={!!passwordError}
              helperText={passwordError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePassword}>
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <StyledSubmitButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              Sign In
            </StyledSubmitButton>
          </StyledForm>
        </StyledPaper>
        {/* <Button onClick={() => sessionStorage.clear()}>logout</Button> */}
      </Container>
    </>
  );
}

export default Login;
