import { Button } from "@mui/material";
import Login from "./Login";
import logo from "./logo.svg";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuth, withRoleProtection } from "./AuthContext/AuthContext";
// import LandingPage from "./LandingPage/LandingPage";
import AdminMain from "./AdminDashboard/AdminMain";
// import PosMain from "./PoshDashboard/PosMain";
// import KitchenMain from "./KitchenDashboard/KitchenMain";

function App() {
  // const { user_teacher } = AuthUser();
  const { user } = useAuth();
  // console.log(user);
  const admin = sessionStorage.getItem("admin");
  // const pos = sessionStorage.getItem("pos");
  // const kitchen = sessionStorage.getItem("kitchen");
  // console.log(user);
  // console.log(admin);
  // const user_admin = "1"
  // const user_teacher = "0"
  // const user_student = "0"
  // if (!getToken()) {

  //   return <Guest />
  // }
  // useEffect(() => {
  //   window.location.reload();
  // }, [admin, pos, kitchen]);
  // console.log(user);
  // if (user == "admin") {
  //   return (
  //     <>
  //       <h1>admin</h1>
  //       <Button onClick={() => sessionStorage.clear()}>clear</Button>
  //     </>
  //   );
  // } else if (user == "pos") {
  //   return (
  //     <>
  //       <h1>pos</h1>
  //       <Button onClick={() => sessionStorage.clear()}>clear</Button>
  //     </>
  //   );
  // } else if (user == "kitchen") {
  //   return (
  //     <>
  //       <h1>kitchen</h1>
  //       <Button onClick={() => sessionStorage.clear()}>clear</Button>
  //     </>
  //   );
  // } else {
  //   return (
  //     // this is for admin dashboard
  //     <Login />
  //   );
  // }
  
  if (admin) {
    return <AdminMain />;
  }else {
    return (
      <Login />
    );
  }
}

export default App;
