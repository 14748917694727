import React from "react";
import StateCard from "./Dashboardcomponents/StateCard";
import { Card, Grid, styled } from "@mui/material";
// import RecentOrderRequest from "./Dashboardcomponent/RecentOrderRequest";
// import MonthlyRevenueByweek from "./Dashboardcomponent/MonthlyRevenueByweek";
// import TrendingItems from "./Dashboardcomponent/TrendingItems";
// import ShowTable from "./Dashboardcomponent/ShowTable";
// import StateCard2 from "./Dashboardcomponent/StateCard2";

function AdminDashboard() {
  const ContentBox = styled("div")(({ theme }) => ({
    margin: "30px",
    marginBottom: "50px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
  }));
  const StyledCard = styled(Card)(({ theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "24px !important",
    background: theme.palette.background.paper,
    [theme.breakpoints.down("sm")]: { padding: "16px !important" },
  }));
  const H4 = styled("h3")(({ theme }) => ({
    fontSize: "20px",
    fontWeight: "700",
    marginBottom: "16px",
    textAlign: "start",
    color: theme.palette.text.secondary,
  }));
  return (
    <div>
      <StateCard />
      {/* <Grid container spacing={2} sx={{ mt: 2, p: 1 }} justifyContent="center">
        <Grid item xs={12} md={8} lg={8} sx={{ p: 1 }}>
          <Card sx={{ px: 3, py: 2, mb: 3 }}>
            <H4 sx={{ textAlign: "center" }}>Revenue By Month</H4>
          </Card>
          <StyledCard sx={{}}>
            <MonthlyRevenueByweek />
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Card sx={{ px: 3, py: 2, mb: 3 }}>
            <H4 sx={{ textAlign: "center" }}>Recent Order</H4>
          </Card>
          <StyledCard>
            <RecentOrderRequest />
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sx={{ p: 1 }}>
          <Card sx={{ px: 3, py: 2, mb: 3 }}>
            <H4 sx={{ textAlign: "center" }}>Top 4 Trending Items of Month</H4>
          </Card>
          <TrendingItems />
        </Grid>
        <Grid item xs={12} md={8} lg={8} sx={{ p: 1 }}>
          <Card sx={{ px: 3, py: 2, mb: 3 }}>
            <H4 sx={{ textAlign: "center" }}>Tables</H4>
          </Card>
          <ContentBox>
            <ShowTable />
          </ContentBox>
        </Grid>
        <Grid item xs={12} md={4} lg={4} sx={{ p: 1 }}>
          <StateCard2 />
        </Grid>
      </Grid> */}
    </div>
  );
}

export default AdminDashboard;
