import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import MDEditor from "@uiw/react-md-editor";
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Rating,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { Add, Check, Clear, Delete, Edit } from "@mui/icons-material";
function AddNewProduct() {
  const location = useLocation();
  const { cat_id, sub_id, productid } = location.state || {};
  // console.log(course_id);
  // console.log(chapter_id);
  // console.log(topic_id);
  // console.log(subtopicid);
  const [image, setImage] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [name, setName] = useState("");
  const [products, setproduct] = useState({});
  const [price, setPrice] = useState("");
  const [catname, setCatname] = useState("");
  const [subname, setSubname] = useState("");
  const [rating, setRating] = useState(0);
  const [desc, setDesc] = useState("");
  const [status, setStatus] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [stock, setStock] = useState(0);
  const [reviewscore, setReviewscore] = useState(0);
  const [reviewcount, setReviewCount] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [popular, setPopular] = useState(1);
  const [mrp, setmrp] = useState("");
  const [allfeatures, setallfeatures] = useState([]);
  const [inputvalue, setinputvalue] = useState("");
  const [isedit, setisedit] = useState(false);
  const [currenttodo, setcurrenttodo] = useState(null);
  const [specification, setspecification] = useState([]);
  const [inputvalue1, setinputvalue1] = useState("");
  const [isedit1, setisedit1] = useState(false);
  const [currenttodo1, setcurrenttodo1] = useState(null);
  const addfeature1 = () => {
    if (inputvalue1.trim() !== "") {
      if (isedit1) {
        const update = specification.map((feature, index) =>
          index === currenttodo1 ? inputvalue1 : feature
        );
        setspecification(update);
        setisedit1(false);
        setcurrenttodo1(null);
      } else {
        setspecification([...specification, inputvalue1]);
      }
      setinputvalue1("");
    }
  };
  const handleinputvalue1 = (e) => {
    setinputvalue1(e.target.value);
  };
  const deletefeature1 = (index) => {
    const newfeature = specification.filter((_, i) => i !== index);
    setspecification(newfeature);
  };
  const handleedit1 = (index) => {
    setinputvalue1(specification[index]);
    setisedit1(true);
    setcurrenttodo1(index);
  };
  const [warranty, setwarranty] = useState([]);
  const [inputvalue2, setinputvalue2] = useState("");
  const [isedit2, setisedit2] = useState(false);
  const [currenttodo2, setcurrenttodo2] = useState(null);
  const addfeature2 = () => {
    if (inputvalue2.trim() !== "") {
      if (isedit2) {
        const update = warranty.map((feature, index) =>
          index === currenttodo2 ? inputvalue2 : feature
        );
        setwarranty(update);
        setisedit2(false);
        setcurrenttodo2(null);
      } else {
        setwarranty([...warranty, inputvalue2]);
      }
      setinputvalue2("");
    }
  };
  const handleinputvalue2 = (e) => {
    setinputvalue2(e.target.value);
  };
  const deletefeature2 = (index) => {
    const newfeature = warranty.filter((_, i) => i !== index);
    setwarranty(newfeature);
  };
  const handleedit2 = (index) => {
    setinputvalue2(warranty[index]);
    setisedit2(true);
    setcurrenttodo2(index);
  };
  const [qna, setqna] = useState([]);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [editingIndex, setEditingIndex] = useState(null);
  const [error, setError] = useState("");
  const handleAddOrUpdate = () => {
    if (!question || !answer) {
      setError("Both fields are required.");
      return;
    }
    setError("");
    if (editingIndex !== null) {
      const updatedTodos = qna.map((todo, index) =>
        index === editingIndex ? { question, answer } : todo
      );
      setqna(updatedTodos);
      setEditingIndex(null);
    } else {
      setqna([...qna, { question, answer }]);
    }
    setQuestion("");
    setAnswer("");
  };
  const handleEdit = (index) => {
    setQuestion(qna[index].question);
    setAnswer(qna[index].answer);
    setEditingIndex(index);
    setError("");
  };

  const handleDelete = (index) => {
    setqna(qna.filter((_, i) => i !== index));
    setError("");
  };

  const [otherinfo, setotherinfo] = useState([]);
  const [inputvalue4, setinputvalue4] = useState("");
  const [isedit4, setisedit4] = useState(false);
  const [currenttodo4, setcurrenttodo4] = useState(null);
  const [loading, setloading] = useState(true);

  const addfeature4 = () => {
    if (inputvalue4.trim() !== "") {
      if (isedit4) {
        const update = otherinfo.map((feature, index) =>
          index === currenttodo4 ? inputvalue4 : feature
        );
        setotherinfo(update);
        setisedit4(false);
        setcurrenttodo4(null);
      } else {
        setotherinfo([...otherinfo, inputvalue4]);
      }
      setinputvalue4("");
    }
  };
  const handleinputvalue4 = (e) => {
    setinputvalue4(e.target.value);
  };
  const deletefeature4 = (index) => {
    const newfeature = otherinfo.filter((_, i) => i !== index);
    setotherinfo(newfeature);
  };
  const handleedit4 = (index) => {
    setinputvalue4(otherinfo[index]);
    setisedit4(true);
    setcurrenttodo4(index);
  };
  const [link, setlink] = useState([]);
  const [inputvalue5, setinputvalue5] = useState("");
  const [isedit5, setisedit5] = useState(false);
  const [currenttodo5, setcurrenttodo5] = useState(null);
  const addfeature5 = () => {
    if (inputvalue5.trim() !== "") {
      if (isedit5) {
        const update = link.map((feature, index) =>
          index === currenttodo5 ? inputvalue5 : feature
        );
        setlink(update);
        setisedit5(false);
        setcurrenttodo5(null);
      } else {
        setlink([...link, inputvalue5]);
      }
      setinputvalue5("");
    }
  };
  const handleinputvalue5 = (e) => {
    setinputvalue5(e.target.value);
  };
  const deletefeature5 = (index) => {
    const newfeature = link.filter((_, i) => i !== index);
    setlink(newfeature);
  };
  const handleedit5 = (index) => {
    setinputvalue5(link[index]);
    setisedit5(true);
    setcurrenttodo5(index);
  };
  const getproducts = () => {
    const token = sessionStorage.getItem("Token");

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://simplesoft.co.in/cgshop/api/v1/getProductById/${productid}`,
      headers: {
        Authorization: `Bearer ${token} `,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setproduct(response.data.product);
        console.log(response.data);
        setSubname(response.data.subcat);
        setloading(false);
      })
      .catch((error) => {
        console.log(error);
        setloading(false);
      });
  };
  const getsubcategory = () => {
    const token = sessionStorage.getItem("Token");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://simplesoft.co.in/cgshop/api/v1/admin/getSubCategory/${cat_id}`,
      headers: {
        Authorization: `Bearer ${token} `,
      },
    };
    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setCatname(response.data.cat);
      })
      .catch((error) => {
        // console.log(error);
        toast.error("something went wrong", {
          position: "bottom-right",
          duration: 3000,
        });
      });
  };
  const [productimages, setproductiamges] = useState([]);
  const [previewproductimg, setpreviewproductimg] = useState([]);

  useEffect(() => {
    getproducts();
    getsubcategory();
  }, [productid]);
  // console.log(image[0]);
  const navigate = useNavigate();
  const goback2 = () => {
    navigate(-3);
  };
  const goback3 = () => {
    navigate(-2);
  };
  const goback4 = () => {
    navigate(-1);
  };

  const productimg = (e) => {
    const files = Array.from(e.target.files);
    setproductiamges((prev) => prev.concat(files));
    const prevurl = files.map((file) => URL.createObjectURL(file));
    setpreviewproductimg((prev) => prev.concat(prevurl));
  };

  // const removeproductimg = (index) => {
  //   console.log(index);
  //https://simplesoft.co.in/cgshop/uploads/10/21/20/17273424606.jpg
  // const newimg = productimages.filter((_, i) => i !== index);
  // const newpreview = previewproductimg.filter((_, i) => i !== index);
  // URL.revokeObjectURL(previewproductimg[index]);
  // setproductiamges(newimg);
  // setpreviewproductimg(newpreview);
  // };

  // const removeproductimg = async (index) => {
  //     try {
  //         // Assuming you have the image data available
  //         const imgUrl = index; // Get the image URL
  //         const imgName = imgUrl.split('/').pop(); // Extract the image name from the URL

  //         // Construct the DELETE request URL
  //         const deleteUrl = `https://simplesoft.co.in/cgshop/admin/deleteImage/${imgName}`;

  //         // Send the DELETE request to the server
  //         await axios.delete(deleteUrl);

  //         // Remove the image locally
  //         const newimg = productimages.filter((_, i) => i !== index);
  //         const newpreview = previewproductimg.filter((_, i) => i !== index);

  //         // Optional: Clean up if you're using Object URLs
  //         URL.revokeObjectURL(previewproductimg[index]);

  //         // Update the state with the new arrays
  //         setproductiamges(newimg);
  //         setpreviewproductimg(newpreview);
  //     } catch (error) {
  //         console.error("Error removing image:", error.response ? error.response.data : error.message);
  //     }
  // };

  // const removeproductimg = async (index) => {
  //     try {
  //         const imgName = index.split('/').pop(); // Extract the image name from the URL
  //         const token = sessionStorage.getItem("Token");

  //         // Construct the DELETE request URL
  //         const deleteUrl = `https://simplesoft.co.in/cgshop/api/v1/admin/deleteImage/${imgName}`;

  //         // Set up the Axios configuration
  //         let config = {
  //             method: "delete",
  //             url: deleteUrl,
  //             headers: {
  //                 Authorization: `Bearer ${token}`, // Add the bearer token
  //                 "Content-type": "application/json", // Set content type if needed
  //             },
  //         };

  //         // Send the DELETE request to the server
  //         await axios.request(config);

  //         // Remove the image locally
  //         const newimg = productimages.filter((_, i) => i !== index);
  //         const newpreview = previewproductimg.filter((_, i) => i !== index);

  //         // Optional: Clean up if you're using Object URLs
  //         URL.revokeObjectURL(previewproductimg[index]);

  //         // Update the state with the new arrays
  //         setproductiamges(newimg);
  //         setpreviewproductimg(newpreview);
  //     } catch (error) {
  //         console.error("Error removing image:", error.response ? error.response.data : error.message);
  //     }
  // };

  const removeproductimg = (name, index) => {
    const imgName = name.split("/").pop(); // Extract the image name from the URL
    const token = sessionStorage.getItem("Token");

    // Construct the DELETE request URL
    const deleteUrl = `https://simplesoft.co.in/cgshop/api/v1/admin/deleteImage/${imgName}`;

    // Set up the Axios configuration
    let config = {
      method: "delete",
      url: deleteUrl,
      headers: {
        Authorization: `Bearer ${token}`, // Add the bearer token
        "Content-type": "application/json", // Set content type if needed
      },
    };

    // Send the DELETE request to the server
    axios
      .request(config)
      .then(() => {
        // // Remove the image locally
        const newimg = productimages.filter((_, i) => i !== index);
        const newpreview = previewproductimg.filter((_, i) => i !== index);

        URL.revokeObjectURL(previewproductimg[index]);

        // Update the state with the new arrays
        setproductiamges(newimg);
        setpreviewproductimg(newpreview);
      })
      .catch((error) => {
        console.error(
          "Error removing image:",
          error.response ? error.response.data : error.message
        );
      });
  };

  useEffect(() => {
    if (productid) {
      setName(products?.title);
      setPrice(products?.price);
      setPreviewImage(
        `https://simplesoft.co.in/cgshop/uploads/${products?.cat_id}/${products?.subcat_id}/${products?.id}/${products?.img}`
      );
      setRating(products.rating);
      setDesc(products.description);
      setStatus(products.status);
      setQuantity(products.item_quantity);
      setStock(products.stock);
      setlink(products?.link);
      setReviewscore(products.reviewScore);
      setReviewCount(products?.reviewCount);
      setDiscount(products?.discount);
      setPopular(products?.popular);
      setmrp(products?.mrp);
      setpreviewproductimg(products.all_images);
      setspecification(products.specifications);
      setallfeatures(products?.features);
      setwarranty(products?.warranty);
      setqna(products?.qna);
      setotherinfo(products?.other_info);
    }
  }, [products, productid]);
  const addnewproduct = () => {
    if (productid) {
      const token = sessionStorage.getItem("Token");
      let data = new FormData();
      // data.append("images[]", image);
      data.append("name", name);
      data.append("features", JSON.stringify(allfeatures));
      data.append("specifications", JSON.stringify(specification));
      data.append("warranty", JSON.stringify(warranty));
      data.append("qna", JSON.stringify(qna));
      data.append("other_info", JSON.stringify(otherinfo));
      data.append("mrp", mrp);
      data.append("link", JSON.stringify(link));
      data.append("cat_id", cat_id);
      data.append("subcat_id", sub_id);
      data.append("price", price);
      data.append("rating", rating);
      data.append("description", desc);
      data.append("status", status);
      data.append("stock", stock);
      data.append("reviewScore", reviewscore);
      data.append("reviewCount", reviewcount);
      data.append("discount", discount);
      data.append("popular", popular);
      image.forEach((file) => {
        data.append("thumbnail[]", file);
      });
      productimages.forEach((img) => {
        data.append("images[]", img);
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `https://simplesoft.co.in/cgshop/api/v1/admin/updateProduct/${productid}`,
        headers: {
          Authorization: `Bearer ${token} `,
          "Content-type": "multipart/form-data",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          goback4();
          toast.success(response.data, {
            position: "bottom-right",
            duration: 3000,
          });
        })
        .catch((error) => {
          // console.log(error);
          toast.error("something went wrong", {
            position: "bottom-right",
            duration: 3000,
          });
        });
    } else {
      let data = new FormData();
      // data.append("images[]", image[0]);
      data.append("name", name);

      // data.append("features", allfeatures);
      data.append("features", JSON.stringify(allfeatures));
      data.append("specifications", JSON.stringify(specification));
      data.append("warranty", JSON.stringify(warranty));
      data.append("qna", JSON.stringify(qna));
      data.append("other_info", JSON.stringify(otherinfo));
      data.append("cat_id", cat_id);
      data.append("mrp", mrp);
      data.append("link", JSON.stringify(link));
      data.append("subcat_id", sub_id);
      data.append("price", price);
      data.append("rating", rating);
      data.append("description", desc);
      data.append("status", status);
      data.append("stock", stock);
      data.append("reviewScore", reviewscore);
      data.append("reviewCount", reviewcount);
      data.append("discount", discount);
      data.append("popular", popular);
      image.forEach((file) => {
        data.append("thumbnail[]", file);
      });
      productimages.forEach((img) => {
        data.append("images[]", img);
      });
      const token = sessionStorage.getItem("Token");
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://simplesoft.co.in/cgshop/api/v1/admin/addProduct",
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          toast.success(response.data, {
            position: "bottom-right",
            duration: 3000,
          });
          goback4();
        })
        .catch((error) => {
          console.log(error);
          toast.error("something went wrong", {
            position: "bottom-right",
            duration: 3000,
          });
        });
    }
  };
  const theme = useTheme();
  const smallscreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage([...image, file]);
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleClearimage = () => {
    const imgName = previewImage.split("/").pop(); // Extract the image name from the URL

    const token = sessionStorage.getItem("Token");

    const deleteUrl = `https://simplesoft.co.in/cgshop/api/v1/admin/deleteThumbnail/${imgName}`;

    let config = {
      method: "post",
      url: deleteUrl,
      headers: {
        Authorization: `Bearer ${token}`, // Add the bearer token
        "Content-type": "application/json", // Set content type if needed
      },
    };

    axios
      .request(config)
      .then(() => {
        setPreviewImage("");
      })
      .catch((error) => {
        console.error(
          "Error removing image:",
          error.response ? error.response.data : error.message
        );
      });
  };
  const handlestatusChange = (event) => {
    setStatus(event.target.value);
  };
  const handlepopularChange = (event) => {
    setPopular(event.target.value);
  };
  const clearall = () => {
    setName("");
    setPreviewImage("");
    setImage([]);
    setPrice(0);
    setRating(0);
    setDesc("");
    setStatus(0);
    setQuantity(0);
    setStock(0);
    setReviewscore(0);
    setReviewCount(0);
    setDiscount(0);
    setPopular(0);
    setpreviewproductimg([]);
    setproductiamges([]);
  };
  const addfeature = () => {
    if (inputvalue.trim() !== "") {
      if (isedit) {
        const update = allfeatures.map((feature, index) =>
          index === currenttodo ? inputvalue : feature
        );
        setallfeatures(update);
        setisedit(false);
        setcurrenttodo(null);
      } else {
        setallfeatures([...allfeatures, inputvalue]);
      }
      setinputvalue("");
    }
  };
  const handleinputvalue = (e) => {
    setinputvalue(e.target.value);
  };
  const deletefeature = (index) => {
    const newfeature = allfeatures.filter((_, i) => i !== index);
    setallfeatures(newfeature);
  };
  const handleedit = (index) => {
    setinputvalue(allfeatures[index]);
    setisedit(true);
    setcurrenttodo(index);
  };
  return (
    <div style={{ marginBottom: "10px" }}>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <div style={{ height: "30px", marginTop: "5px", marginLeft: "10px" }}>
            <Breadcrumbs aria-label="breadcrumb">
              <Box onClick={goback2} sx={{ cursor: "pointer" }}>
                <Typography color="text.primary" fontSize={16}>
                  Home
                </Typography>
              </Box>
              <Box onClick={goback3} sx={{ cursor: "pointer" }}>
                <Typography fontSize={16} color="text.primary">
                  {catname}
                </Typography>
              </Box>
              <Box onClick={goback4} sx={{ cursor: "pointer" }}>
                <Typography fontSize={16} color="text.primary">
                  {subname}
                </Typography>
              </Box>
              <Typography color="text.primary" fontSize={16} variant="h5">
                {products?.title || "Add Product"}
              </Typography>
            </Breadcrumbs>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6} justifyContent="center">
              <Box>
                <Typography align="center" variant="h3">
                  Add Menu Item
                </Typography>
                <Grid container spacing={2} sx={{ p: 1 }}>
                  <Grid item xs={8} lg={12}>
                    <Typography fontWeight={600}>Item Name</Typography>
                    <TextField
                      sx={{ mt: 0.5 }}
                      fullWidth
                      placeholder="Item Name"
                      variant="outlined"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4} lg={6}>
                    <Typography fontWeight={600}>Price</Typography>
                    <TextField
                      sx={{ mt: 0.5 }}
                      fullWidth
                      placeholder="Item Price"
                      variant="outlined"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Typography fontWeight={600}>Ratings</Typography>
                    <br />
                    <Rating
                      name="simple-controlled"
                      value={rating}
                      size="large"
                      onChange={(event, newValue) => {
                        setRating(newValue);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <Typography fontWeight={600}>SKU</Typography>

                    <TextField
                      sx={{ mt: 0.5 }}
                      fullWidth
                      placeholder="SKU"
                      variant="outlined"
                      value={quantity}
                      type="number"
                      onChange={(e) => setQuantity(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4} lg={3}>
                    <Typography fontWeight={600}>Item Status 1/0</Typography>
                    <FormControl sx={{ mt: 0.5, minWidth: 120 }}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={status}
                        onChange={handlestatusChange}
                      >
                        <MenuItem value={1}>True</MenuItem>
                        <MenuItem value={0}>False</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} lg={3}>
                    <Typography fontWeight={600}>Item Stock</Typography>

                    <TextField
                      sx={{ mt: 0.5 }}
                      fullWidth
                      placeholder="Item Stock"
                      variant="outlined"
                      value={stock}
                      onChange={(e) => setStock(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4} lg={3}>
                    <Typography fontWeight={600}>Review Score</Typography>

                    <TextField
                      sx={{ mt: 0.5 }}
                      fullWidth
                      placeholder="Review Score"
                      variant="outlined"
                      value={reviewscore}
                      onChange={(e) => setReviewscore(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4} lg={3}>
                    <Typography fontWeight={600}>Review Count</Typography>
                    <TextField
                      sx={{ mt: 0.5 }}
                      fullWidth
                      placeholder="Review Count"
                      variant="outlined"
                      value={reviewcount}
                      onChange={(e) => setReviewCount(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4} lg={3}>
                    <Typography fontWeight={600}>Item Mrp</Typography>

                    <TextField
                      sx={{ mt: 0.5 }}
                      fullWidth
                      placeholder="Item Mrp"
                      variant="outlined"
                      value={mrp}
                      onChange={(e) => setmrp(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4} lg={3}>
                    <Typography fontWeight={600}>Item Discount</Typography>
                    <TextField
                      sx={{ mt: 0.5 }}
                      fullWidth
                      placeholder="discount"
                      variant="outlined"
                      value={discount}
                      onChange={(e) => setDiscount(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4} lg={3}>
                    <Typography fontWeight={600}>Popular 1/0</Typography>
                    <FormControl sx={{ mt: 0.5, minWidth: 120 }} fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={popular}
                        onChange={handlepopularChange}
                      >
                        <MenuItem value={1}>True</MenuItem>
                        <MenuItem value={0}>False</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Typography fontWeight={600}>Add Link</Typography>
                    <TextField
                      sx={{ mt: 0.5 }}
                      fullWidth
                      placeholder="Add Links"
                      variant="outlined"
                      value={inputvalue5}
                      onChange={handleinputvalue5}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton color="primary" onClick={addfeature5}>
                              {isedit5 ? <Check /> : <Add />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Typography fontWeight={600}>Link List</Typography>
                    {link?.map((item, index) => (
                      <>
                        <ul>
                          <li>
                            <Typography>{item}</Typography>
                            <Button onClick={() => handleedit5(index)}>
                              Edit
                            </Button>
                            <Button onClick={() => deletefeature5(index)}>
                              Delete
                            </Button>
                          </li>
                        </ul>
                      </>
                    ))}
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Typography fontWeight={600}>Description</Typography>
                    <TextField
                      sx={{ mt: 0.5 }}
                      fullWidth
                      placeholder="Item Desc"
                      variant="outlined"
                      value={desc}
                      rows={3}
                      multiline
                      onChange={(e) => setDesc(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Typography fontWeight={600}>Features</Typography>
                    <TextField
                      sx={{ mt: 0.5 }}
                      fullWidth
                      placeholder="Add Item features"
                      variant="outlined"
                      value={inputvalue}
                      rows={3}
                      multiline
                      onChange={handleinputvalue}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton color="primary" onClick={addfeature}>
                              {isedit ? <Check /> : <Add />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Typography fontWeight={600}>Features List</Typography>
                    {allfeatures.map((item, index) => (
                      <>
                        <ul>
                          <li>
                            <Typography>{item}</Typography>
                            <Button onClick={() => handleedit(index)}>
                              Edit
                            </Button>
                            <Button onClick={() => deletefeature(index)}>
                              Delete
                            </Button>
                          </li>
                        </ul>
                      </>
                    ))}
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Typography fontWeight={600}>Specifications</Typography>
                    <TextField
                      sx={{ mt: 0.5 }}
                      fullWidth
                      placeholder="Add Item Specification"
                      variant="outlined"
                      value={inputvalue1}
                      rows={3}
                      multiline
                      onChange={handleinputvalue1}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton color="primary" onClick={addfeature1}>
                              {isedit1 ? <Check /> : <Add />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Typography fontWeight={600}>
                      Specifications List
                    </Typography>
                    {specification.map((item, index) => (
                      <>
                        <ul>
                          <li>
                            <Typography>{item}</Typography>
                            <Button onClick={() => handleedit1(index)}>
                              Edit
                            </Button>
                            <Button onClick={() => deletefeature1(index)}>
                              Delete
                            </Button>
                          </li>
                        </ul>
                      </>
                    ))}
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Typography fontWeight={600}>Warranty</Typography>
                    <TextField
                      sx={{ mt: 0.5 }}
                      fullWidth
                      placeholder="Add Item Warranty"
                      variant="outlined"
                      value={inputvalue2}
                      rows={3}
                      multiline
                      onChange={handleinputvalue2}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton color="primary" onClick={addfeature2}>
                              {isedit2 ? <Check /> : <Add />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Typography fontWeight={600}>Warranty List</Typography>
                    {warranty.map((item, index) => (
                      <>
                        <ul>
                          <li>
                            <Typography>{item}</Typography>
                            <Button onClick={() => handleedit2(index)}>
                              Edit
                            </Button>
                            <Button onClick={() => deletefeature2(index)}>
                              Delete
                            </Button>
                          </li>
                        </ul>
                      </>
                    ))}
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Typography fontWeight={600}>Qna</Typography>
                    {error && <Alert severity="error">{error}</Alert>}
                    <TextField
                      label="Question"
                      value={question}
                      onChange={(e) => setQuestion(e.target.value)}
                      variant="outlined"
                      fullWidth
                      style={{ marginBottom: "16px" }}
                    />
                    <TextField
                      label="Answer"
                      value={answer}
                      onChange={(e) => setAnswer(e.target.value)}
                      variant="outlined"
                      fullWidth
                      style={{ marginBottom: "16px" }}
                    />

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAddOrUpdate}
                    >
                      {editingIndex !== null ? "Update" : "Add"}
                    </Button>
                    <Typography fontWeight={600}>Qna List</Typography>
                    <List>
                      {qna.map((todo, index) => (
                        <ListItem components={"li"} key={index}>
                          <ListItemText
                            primary={todo.question}
                            secondary={todo.answer}
                          />
                          <Button onClick={() => handleEdit(index)}>
                            Edit
                          </Button>
                          <Button onClick={() => handleDelete(index)}>
                            Delete
                          </Button>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Typography fontWeight={600}>Otherinfo</Typography>
                    <TextField
                      sx={{ mt: 0.5 }}
                      fullWidth
                      placeholder="Add other info"
                      variant="outlined"
                      value={inputvalue4}
                      rows={3}
                      multiline
                      onChange={handleinputvalue4}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton color="primary" onClick={addfeature4}>
                              {isedit4 ? <Check /> : <Add />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Typography fontWeight={600}>Other info List</Typography>
                    {otherinfo.map((item, index) => (
                      <>
                        <ul>
                          <li>
                            <Typography>{item}</Typography>
                            <Button onClick={() => handleedit4(index)}>
                              Edit
                            </Button>
                            <Button onClick={() => deletefeature4(index)}>
                              Delete
                            </Button>
                          </li>
                        </ul>
                      </>
                    ))}
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Typography fontWeight={600}>Product Images</Typography>
                    <input
                      type="file"
                      multiple
                      onChange={productimg}
                      accept="image/*"
                    />
                  </Grid>
                  {previewproductimg.map((photo, index) => (
                    <Grid xs={4} lg={4} gap={5} padding={2}>
                      <img src={photo} width={100} height={100} alt="" />
                      <IconButton
                        color="error"
                        onClick={() => removeproductimg(photo, index)}
                      >
                        <Clear />
                      </IconButton>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sx={{ p: 1 }}>
              <Box>
                <Grid container spacing={2} sx={{ p: 2 }}>
                  <Grid item xs={12} md={12} lg={12} sx={{ p: 1 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography fontWeight={600}>
                        Thumbnail Preview
                      </Typography>
                      {(previewImage? null:0) ? (
                        <Button onClick={handleClearimage}>Clear Image</Button>
                      ) : (
                        <Grid item xs={12} lg={12}>
                          <input
                            type="file"
                            onChange={handleImageUpload}
                            accept="image/*"
                          />
                        </Grid>
                      )}
                    </div>
                  </Grid>
                  {previewImage && (
                    <div>
                      <Grid
                        item
                        sx={{ p: 1 }}
                        xs={12}
                        md={12}
                        lg={12}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <img
                          src={previewImage}
                          // src="https://t3.ftcdn.net/jpg/02/52/38/80/360_F_252388016_KjPnB9vglSCuUJAumCDNbmMzGdzPAucK.jpg"
                          alt="Preview"
                          style={{
                            width: smallscreen ? "375px" : "95%",
                            height: smallscreen ? "300px" : "450px",
                            objectFit: "fill",
                          }}
                        />
                      </Grid>
                    </div>
                  )}
                </Grid>
                <div></div>
              </Box>
            </Grid>
          </Grid>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Button color="error" onClick={clearall}>
              Clear All
            </Button>
            <Button variant="contained" onClick={addnewproduct}>
              {productid ? "Update Product " : "Add Product "}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export default AddNewProduct;
