import React, { useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Rating,
  Select,
  TextField,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Add,
  ArrowForward,
  Clear,
  Close,
  DeleteTwoTone,
  EditTwoTone,
  Info,
} from "@mui/icons-material";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

function Productpage() {
  const { catid, subid } = useParams();
  const [subname, setSubname] = useState("");
  //   console.log(courseid);
  //   console.log(chapterid);
  const [products, setProducts] = useState([]);
  const [catname, setCatname] = useState("");

  const getproducts = () => {
    const token = sessionStorage.getItem("Token");

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://simplesoft.co.in/cgshop/api/v1/admin/ProductListCatSub/${catid}/${subid}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data.products);
        setProducts(response.data.products);
        setSubname(response.data.subcat);
      })
      .catch((error) => {
        // console.log(error);
        toast.error("something went wrong", {
          position: "bottom-right",
          duration: 3000,
        });
      });
  };
  const getsubcategory = () => {
    const token = sessionStorage.getItem("Token");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://simplesoft.co.in/cgshop/api/v1/admin/getSubCategory/${catid}`,
      headers: {
        Authorization: `Bearer ${token} `,
      },
    };
    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setCatname(response.data.cat);
      })
      .catch((error) => {
        // console.log(error);
        toast.error("something went wrong", {
          position: "bottom-right",
          duration: 3000,
        });
      });
  };
  useEffect(() => {
    getproducts();
    getsubcategory();
  }, []);

  const [deleteDialog, setdeleteDialog] = React.useState(false);
  const [deleteid, setDeleteid] = useState("");
  const [deletename, setDeletename] = useState("");
  const deleteDialogOpen = (id, name) => {
    setdeleteDialog(true);
    setDeleteid(id);
    setDeletename(name);
  };
  const deleteDialogClose = () => {
    setdeleteDialog(false);
    setDeleteid("");
    setDeletename("");
  };
  const deleteproduct = (id) => {
    const token = sessionStorage.getItem("Token");

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `https://simplesoft.co.in/cgshop/api/v1/admin/deleteProduct/${deleteid}`,
      headers: {
        Authorization: `Bearer ${token} `,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        getproducts();
        deleteDialogClose();
        toast.success(response.data, {
          position: "bottom-right",
          duration: 3000,
        });
      })
      .catch((error) => {
        // console.log(error);
        toast.error("something went wrong", {
          position: "bottom-right",
          duration: 3000,
        });
      });
  };
  const handleClick = () => {
    const data = {
      cat_id: catid,
      sub_id: subid,
    };
    navigate("/addnewproduct", { state: data });
  };
  const handleClick1 = (id) => {
    const data = {
      cat_id: catid,
      sub_id: subid,
      productid: id,
    };
    navigate("/addnewproduct", { state: data });
  };
  //   console.log(topics)
  const theme = useTheme();
  const smallscreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const goback3 = () => {
    navigate(-2);
  };
  const goback2 = () => {
    navigate(-1);
  };
  // const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const [previewdata, setpreviewdata] = useState([]);
  const handleClickOpen = (productid) => {
    setOpen(true);
    const filtervalue = products?.filter((value) => value.id === productid);
    setpreviewdata(filtervalue[0]);
  };

  const handleClose = () => {
    setOpen(false);
    setpreviewdata([]);
  };

  // const goback3 = () => {
  //   navigate("/admin/dashboard");
  // };
  return (
    <div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <Dialog
        open={deleteDialog}
        onClose={deleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete??</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            are you sure delete {deletename} &nbsp;product?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteDialogClose}>Cancel</Button>
          <Button color="error" onClick={deleteproduct} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ height: "30px", marginTop: "5px", marginLeft: "10px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Box onClick={goback3} sx={{ cursor: "pointer" }}>
            <Typography fontSize={16} color="text.primary">
              Home
            </Typography>
          </Box>
          <Box onClick={goback2} sx={{ cursor: "pointer" }}>
            <Typography fontSize={16} color="text.primary">
              {catname}
            </Typography>
          </Box>
          <Box>
            <Typography fontSize={16} variant="h5" color="text.primary">
              {subname}
            </Typography>
          </Box>
        </Breadcrumbs>
      </div>
      <Grid container spacing={0} sx={{ p: 1, mb: 7 }}>
        {products.map((item, index) => (
          <Grid key={index} item xs={6} md={4} lg={4} sx={{ p: 1 }}>
            <Card
              sx={{
                height: smallscreen ? 380 : 360,
                width: "100%",
                borderRadius: 2,
                // width:'100%',
                p: 1,
                // backgroundSize: "cover",
                transform: "scale(0.9)",
                transition: " all ease-in 0.2s",
                "&:hover": {
                  transform: "scale(1)",
                  filter: `drop-shadow(4px 4px 5px black)`,
                },
              }}
              key={index}
            >
              <img
                height={150}
                width={"100%"}
                alt={item.title}
                style={{
                  objectFit: "fill",
                  borderRadius: 10,
                  // border: "1px solid black",
                }}
                src={`https://simplesoft.co.in/cgshop/uploads/${item.cat_id}/${item.subcat_id}/${item.id}/${item.img}`}
                // title={item.title}
              />
              <Divider />
              <CardContent sx={{}}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: smallscreen ? "column" : "row",
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h5"
                    fontSize={15}
                    component="div"
                    sx={{ whiteSpace: "pre-wrap" }}
                  >
                    Name:{item.title?.toUpperCase()}
                  </Typography>
                  <Rating
                    sx={{
                      fontSize: "14px",
                      mb: 1,
                    }}
                    readOnly
                    name="simple-controlled"
                    value={item.rating}
                    size="small"
                  />
                </div>
                <Typography
                  gutterBottom
                  variant="h5"
                  fontSize={15}
                  component="div"
                  sx={{ whiteSpace: "pre-wrap" }}
                >
                  Price: {item.price} Rs
                </Typography>
                <Typography
                  gutterBottom
                  variant="body1"
                  component="div"
                  sx={{
                    textOverflow: "ellipsis",
                    overflow: "scroll",
                    width: smallscreen ? "8rem" : "18rem",
                    wordWrap: "break-word",
                    WebkitLineClamp: 3,
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  <span style={{ fontWeight: 740 }}>Desc:</span>&nbsp;
                  {item.description}
                </Typography>
              </CardContent>
              {/*  sx={{ position: "fixed", bottom: 0, left: 0 }} */}
              {/* <CardActions > */}
              {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
              <div style={{ position: "fixed", left: 7 }}>
                <IconButton
                  color="primary"
                  onClick={() => handleClickOpen(item.id)}
                >
                  <Tooltip title="Details">
                    <Info />
                  </Tooltip>
                </IconButton>
                <IconButton
                  onClick={() => handleClick1(item.id)}
                  color="primary"
                  size="small"
                  sx={{
                    "&:hover": {
                      transform: "scale(1.1)",
                      transition: "all 0.5s",
                    },
                  }}
                >
                  <Tooltip title="Edit">
                    <EditTwoTone />
                  </Tooltip>
                </IconButton>
              </div>
              <div style={{ position: "fixed", right: 7 }}>
                <IconButton
                  onClick={() => deleteDialogOpen(item.id, item.name)}
                  color="error"
                  size="small"
                  sx={{
                    "&:hover": {
                      transform: "scale(1.1)",
                      transition: "all 0.5s",
                    },
                  }}
                >
                  <Tooltip title="Delete">
                    <DeleteTwoTone />
                  </Tooltip>
                </IconButton>
              </div>
              {/* </div> */}
              {/* </CardActions> */}
              {/* <IconButton
                  component={Link}
                  to={`/course/${courseid}/topics/${chapterid}/subtopic/${item.id}`}
                  color="primary"
                  size="small"
                  sx={{
                    "&:hover": {
                      transform: "scale(1.1)",
                      transition: "all 0.5s",
                    },
                  }}
                >
                  <Tooltip title={`go to ${item.name}`}>
                    <ArrowForward />
                  </Tooltip>
                </IconButton> */}
            </Card>
          </Grid>
        ))}
      </Grid>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Button
          // color="warning"
          className="target-1"
          variant="contained"
          sx={{ width: "80%", p: 1, position: "fixed", bottom: 10 }}
          onClick={handleClick}
        >
          <Add /> Add New Product
        </Button>
      </div>
      <Dialog
        open={open}
        maxWidth={"lg"}
        onClose={handleClose}
        fullScreen={false}
        aria-labelledby="responsive-dialog-title"
      >
        <Container>
          <IconButton
            sx={{ position: "absolute", right: 0, top: 2 }}
            size="small"
            onClick={handleClose}
            color="error"
          >
            <Clear />
          </IconButton>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <Box>
                <Grid container spacing={2} sx={{ p: 1 }}>
                  <Grid item xs={7} lg={12} display="flex" alignItems="center">
                    <Typography fontWeight={800}>Item Name</Typography>&nbsp;
                    <Typography fontWeight={600}>
                      {previewdata.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={5} lg={6} display="flex" alignItems="center">
                    <Typography fontWeight={800}>Price</Typography>&nbsp;
                    <Typography fontWeight={600}>
                      {previewdata.price} Rs
                    </Typography>
                  </Grid>
                  <Grid item xs={6} lg={6} display="flex">
                    <Typography fontWeight={600}>Ratings</Typography>
                    <Rating
                      sx={{
                        fontSize: smallscreen ? "14px" : "19px",
                        mt: smallscreen ? 0.4 : 0.2,
                        ml: 1,
                      }}
                      readOnly
                      name="simple-controlled"
                      value={previewdata.rating}
                      // size={smallscreen ? "small" : "medium"}
                    />
                  </Grid>
                  <Grid item xs={6} lg={3} display="flex">
                    <Typography fontWeight={800}>Item Quantity</Typography>{" "}
                    &nbsp;
                    <Typography fontWeight={600}>
                      {previewdata.item_quantity}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} lg={3} display="flex">
                    <Typography fontWeight={800}>Item Status</Typography>&nbsp;
                    <Typography fontWeight={600}>
                      {previewdata.status === 1 ? "True" : "False"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} lg={3} display="flex">
                    <Typography fontWeight={800}>Item Stock</Typography> &nbsp;
                    <Typography fontWeight={600}>
                      {previewdata.stock}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} lg={3} display="flex">
                    <Typography fontWeight={800}>Review Score</Typography>&nbsp;
                    <Typography fontWeight={600}>
                      {previewdata.reviewScore}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} lg={4} display="flex">
                    <Typography fontWeight={800}>Review Count</Typography>&nbsp;
                    <Typography fontWeight={600}>
                      {previewdata.reviewCount}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} lg={4} display="flex">
                    <Typography fontWeight={800}>Item Discount</Typography>
                    &nbsp;
                    <Typography fontWeight={600}>
                      {previewdata.discount}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} lg={4} display="flex">
                    <Typography fontWeight={800}>Popular</Typography>&nbsp;
                    <Typography fontWeight={600}>
                      {previewdata.popular === 1 ? "True" : "False"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Typography
                      gutterBottom
                      variant="body1"
                      component="div"
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: smallscreen ? "17rem" : "36rem",
                        wordWrap: "break-word",
                        WebkitLineClamp: 4,
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      <span style={{ fontWeight: 740 }}>Description:</span>
                      &nbsp;
                      {previewdata.description}
                    </Typography>
                  </Grid>

                  <Grid item xs={4} lg={4}>
                    <img
                      src={previewdata.link}
                      // src="https://t3.ftcdn.net/jpg/02/52/38/80/360_F_252388016_KjPnB9vglSCuUJAumCDNbmMzGdzPAucK.jpg"
                      alt="Preview"
                      style={{
                        width: "100%",
                        height: smallscreen ? "100px" : "180px",
                        objectFit: smallscreen ? "contain" : "fill",
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} lg={4}>
                    <img
                      src={previewdata.link}
                      // src="https://t3.ftcdn.net/jpg/02/52/38/80/360_F_252388016_KjPnB9vglSCuUJAumCDNbmMzGdzPAucK.jpg"
                      alt="Preview"
                      style={{
                        width: "100%",
                        height: smallscreen ? "100px" : "180px",
                        objectFit: smallscreen ? "contain" : "fill",
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} lg={4}>
                    <img
                      src={previewdata.link}
                      // src="https://t3.ftcdn.net/jpg/02/52/38/80/360_F_252388016_KjPnB9vglSCuUJAumCDNbmMzGdzPAucK.jpg"
                      alt="Preview"
                      style={{
                        width: "100%",
                        height: smallscreen ? "100px" : "180px",
                        objectFit: smallscreen ? "contain" : "fill",
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}></Grid>
                  <div>
                    <Grid
                      item
                      sx={{ p: 1 }}
                      xs={12}
                      md={12}
                      lg={12}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <img
                        src={previewdata.link}
                        // src="https://t3.ftcdn.net/jpg/02/52/38/80/360_F_252388016_KjPnB9vglSCuUJAumCDNbmMzGdzPAucK.jpg"
                        alt="Preview"
                        style={{
                          marginLeft: "10px",
                          width: smallscreen ? "290px" : "100%",
                          height: smallscreen ? "300px" : "450px",
                          objectFit: "fill",
                        }}
                      />
                    </Grid>
                  </div>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
        {/* <Button size="small" onClick={handleClose} color="error">
          CLOSE
        </Button> */}
      </Dialog>
    </div>
  );
}

export default Productpage;
