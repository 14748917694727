import {
  Add,
  Clear,
  DeleteTwoTone,
  EditTwoTone,
  Search,
} from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import axios from "axios";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Joyride from "react-joyride";

function AllProductPage() {
  const [category, setcategory] = useState([]);
  const [menuItems, setMenuitems] = useState([]);
  const [search, setSearch] = useState("");
  const [filterCategory, setFilterCategory] = useState(0);
  const [searchopen, setsearchopen] = useState(false);
  const Showcategory = () => {
    const token = sessionStorage.getItem("Token");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://simplesoft.co.in/cgshop/api/v1/admin/CategoryList",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setcategory(response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  const ShowMenuItems = () => {
    const token = sessionStorage.getItem("Token");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://simplesoft.co.in/cgshop/api/v1/admin/ProductList",
      headers: {
        Authorization: `Bearer ${token} `,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setMenuitems(response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  useEffect(() => {
    Showcategory();
    ShowMenuItems();
  }, []);

  const theme = useTheme();
  const smallscreen = useMediaQuery(theme.breakpoints.down("sm"));
  //this filter for sort by category name
  const filter1 = menuItems.filter((items) => {
    if (!filterCategory) return true;
    return items.cat_id === filterCategory;
  });
  //this filter for type of item i.e veg or non-veg

  //this filter for search by keyword in search field
  const filterItems = filter1.filter((item) =>
    item?.name?.toUpperCase()?.includes(search?.toUpperCase())
  );

  // console.log(typeof(categoryid));

  const selectFiltercategory = (e) => {
    setFilterCategory(e.target.value);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [search, filterCategory]);
  //   const steps = [
  //     { target: ".target-1", content: "This Button For Add Items in Menu Card" },
  //     { target: ".target-2", content: "This Dropdown for Filter by Category" },
  //     {
  //       target: ".target-3",
  //       content: "This Checkbox for Filter Type of Items i.e Veg or non Veg",
  //     },
  //     {
  //       target: ".target-4",
  //       content: "This Search Icon for Search menu item by entering their name",
  //     },
  //     { target: ".id-0", content: "for edit Menu items" },
  //     { target: ".id-1", content: "for delete menu item" },
  //   ];
  return (
    <div>
      {/* <Joyride
        steps={steps}
        run={run}
        continuous
        showProgress
        showSkipButton
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      /> */}

      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      {searchopen && (
        <Fade in={searchopen}>
          <TextField
            sx={{
              "& fieldset": { borderRadius: 20 },
              position: "sticky",
              top: 89,
              bgcolor: "white",
              borderRadius: 20,
              zIndex: 5,
              transition: "all 2s",
            }}
            fullWidth
            placeholder="Search here..."
            label="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {search && (
                    <Clear
                      sx={{ cursor: "pointer" }}
                      onClick={() => setSearch("")}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Fade>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          bgcolor: "white",
          position: "sticky",
          top: searchopen ? 145 : 83,
          zIndex: 5,
        }}
      >
        <FormControl sx={{ width: 200, p: 1 }} className="target-2">
          <Select
            value={filterCategory}
            placeholder="Category"
            onChange={selectFiltercategory}
          >
            <MenuItem value={0}>All</MenuItem>
            {category.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <ButtonGroup
          variant="outlined"
          size="small"
          aria-label="Basic button group"
        >
          <IconButton
            className="target-4"
            color="primary"
            variant="contained"
            onClick={() => {
              setsearchopen(!searchopen);
              setSearch("");
            }}
          >
            {searchopen ? <Clear /> : <Search />}
          </IconButton>
        </ButtonGroup>
      </Box>
      {filterItems.length > 0 && search && (
        <Typography variant="h4" sx={{ mt: 3, ml: 2 }}>
          {filterItems.length} &nbsp;{" "}
          {filterItems.length > 1 ? "products" : "product"} found by this
          Keyword "{search}" word
        </Typography>
      )}
      {filterItems.length > 0 ? (
        <Grid container spacing={2} sx={{ p: 1, mb: 7 }}>
          {filterItems.map((item, index) => (
            <Grid key={index} item xs={6} md={4} lg={4}>
              <Card
                sx={{
                  maxWidth: 345,
                  height: 330,
                  borderRadius: 2,
                  backgroundSize: "cover",
                  transform: "scale(0.9)",
                  transition: " all ease-in 0.2s",
                  "&:hover": {
                    // color: "red",
                    transform: "scale(1)",
                    filter: `drop-shadow(8px 8px 10px black)`,
                  },
                }}
                key={index}
              >
                <CardMedia
                  sx={{
                    height: 230,
                    transform: "scale(1)",
                    "&:hover": {
                      transform: "scale(1.1)",
                      transition: "all 0.5s",
                    },
                  }}
                  image={`https://simplesoft.co.in/cgshop/uploads/${item.cat_id}/${item.subcat_id}/${item.id}/${item.img}`}
                  title={item.name}
                />
                <CardContent>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography gutterBottom variant="h5" component="div">
                      {item.name}
                    </Typography>
                  </div>
                  <Typography gutterBottom variant="h5" component="div">
                    Price: {item.price}&#8360;
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginBottom: 60,
          }}
        >
          <img
            style={{}}
            height={300}
            src="https://cdni.iconscout.com/illustration/premium/thumb/error-404-4344461-3613889.png"
            alt=""
          />
          <Typography variant="h3">No Item Found</Typography>
          <Typography sx={{ mb: 5 }} variant="h6">
            Try something else
          </Typography>
        </div>
      )}
    </div>
  );
}
export default AllProductPage;
