import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect } from "react";
import { RemoveRedEye } from "@mui/icons-material";
import { Link } from "react-router-dom";
import PdfCard from "./Pdffile/PdfCard";
function GetallOrders() {
  const [getorders, setorders] = useState([]);
  const token = sessionStorage.getItem("Token");

  const getalldata = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://simplesoft.co.in/cgshop/api/v1/admin/orders",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token} `,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setorders(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getalldata();
  }, []);
  console.log(getorders)
  const PreviewButton = ({ params }) => {
    return (
      <>
        <Link to={`/admin/orders/${params.row.id}`}>
          <Button>
            <RemoveRedEye />
          </Button>
        </Link>
      </>
    );
  };
  const columns = [
    // {
    //   field: "user_id",
    //   headerName: "User Id",
    //   width: 150,
    // },
    {
      field: "name",
        headerName: "Customer name",
        width: 150,
    },

    {
      field: "order_number",
      headerName: "Order Number",
      width: 150,
    },
    {
      field: "total_price",
      headerName: "Total Price",
      type: "number",
      width: 110,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      type: "number",
      width: 110,
    },
    
    // {
    //   field: "details",
    //   headerName: "Details",
    //   width: 200,
    //   renderCell: (params) => (
    //     <PdfCard title={params.row.customer_name} data={params.row} />
    //   ),
    // },
    {
      field: "preview",
      headerName: "preview",
      width: 300,
      renderCell: (params) => <PreviewButton params={params} />,
    },
  ];

  return (
    <div>
      <Box sx={{ height: 400, width: "100%", p: 1 }}>
        <DataGrid
          rows={getorders}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          slots={{ toolbar: GridToolbar }}
          pageSizeOptions={[10]}
        />
      </Box>
    </div>
  );
}

export default GetallOrders;
